import Cookies from 'js-cookie';
import _ from 'lodash';
import md5 from 'md5';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { APP_IMG } from 'src/constants';
// import {
//   KEY_EVENT,
//   sendDataLayerGlobal
// } from 'src/utils/datalayer/send-datalayer';

const SectionLandingPage = ({ data = [] }) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();
  const [visible, setVisible] = useState(false);

  function openCookieAccept () {
    const cookieFirstPage = Cookies.get('first_page');
    if (cookieFirstPage) {
      document.getElementById('landing-page')?.remove();
    }
    if (cookieFirstPage !== undefined) {
      setVisible(!cookieFirstPage);
    }
  }

  async function handleAccept () {
    try {
      const _id = md5('first_page');
      const _expires = 1 / 48;

      Cookies.set('first_page', _id, { expires: _expires });
      const cookieFirstPage = Cookies.get('first_page');

      if (cookieFirstPage !== undefined) {
        router.reload(window.location.pathname);
      }
    } catch (err) {
      console.log('Error: Please enable website cookies. ' + err);
    }
    // sendDataLayerGlobal({
    //   key: KEY_EVENT.TRACK_EVENT,
    //   event: KEY_EVENT.SUBMIT,
    //   data: {
    //     title: 'เข้าสู่เว็บไซต์',
    //     link: '/'
    //   }
    // });
  }

  useEffect(() => {
    openCookieAccept();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      document.getElementById('__next').classList?.add('show-landing-page');
      document
        .getElementById('truehits_div')
        ?.setAttribute('style', 'display:none;');
    } else {
      document.getElementById('__next').classList?.remove('show-landing-page');
      document
        .getElementById('truehits_div')
        ?.setAttribute('style', 'display:flex;');
    }
  }, [visible]);
  // console.log(data);
  return (
    <>
      <SectionWrapper
        id='landing-page'
        className='landing-wrapper'
        dataImage={{
          imagePath: data?.imagePath,
          imagePathMobile: data?.imagePathMobile
        }}
        onClick={() => handleAccept()}
      >
        <div className='footer-landing'>
          <button id='btn-inside' aria-label='inside' title='inside'>
            <span> เข้าสู่เว็บไซต์ </span>
          </button>
        </div>
      </SectionWrapper>
    </>
  );
};
const SectionWrapper = styled.section`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  background-image: ${({ dataImage }) =>
    `url(${APP_IMG}/${dataImage?.imagePath})`};
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(256, 256, 256, 1);
  &section {
    padding: 0;
  }
  .footer-landing {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 200px;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    /* background: rgba(256, 256, 256, 1); */
    /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 90%); */
  }
  button {
    /* position: absolute;
    bottom: 10%;
    right: 5%; */
    /* right: 50%;
    transform: translate(50%, 0); */

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 40px;
    max-width: 200px;
    margin: 20px 30px 20px auto;
    padding: 5px 10px;
    cursor: pointer !important;
    /* background: linear-gradient(
      350deg,
      rgba(82, 32, 0, 1) 0%,
      rgba(147, 62, 5, 1) 25%,
      rgba(239, 128, 8, 1) 50%,
      rgba(147, 62, 5, 1) 75%,
      rgba(82, 32, 0, 1) 100%
    ); */
    color: #fff;
    background-color:#000060;
    /* background-image: linear-gradient(
      1deg,
      rgb(84, 3, 3),
      rgb(221, 31, 35) 99%
    ); */
    font-size: 18px;
    border: none;

    /* border: 2px solid rgba(147, 62, 5, 1); */
    border-radius: 10px;
    box-shadow: 0px 2px 3px #000;
  }
  @media (min-width: 1044px) {
    background-size: cover;
  }

  @media (min-width: 767px) and (max-width: 900px) {
    .footer-landing {
      height: 200px;
      /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 80%); */
    }
  }

  @media (max-width: 767px) {
    background-image: ${({ dataImage }) =>
      `url(${APP_IMG}/${dataImage?.imagePathMobile})`};
    .footer-landing {
      justify-content: center;
      /* height: 100px; */
      /* background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%); */
    }
  }

  @media (max-width: 475px) {
    /* .footer-landing {
      background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%);
    } */
    button {
      /* bottom: 10%;
      right: 50%;
      transform: translate(50%, 0); */
      max-width: 150px;
      font-size: 16px;
      margin: 20px auto 20px auto;
    }
  }
  /* } */
`;
export default SectionLandingPage;
